export default function LoadingTip(props) {
    return (
        props.is_loading ? (
            <div className="progressTip d-flex align-items-center justify-content-center" style={{
                width: '100vw',
                height: '100vh',
                background: 'rgba(0, 0, 0, 0.3)',
                zIndex: 1056,
                position: 'fixed',
                top: 0,
                left: 0,
            }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>) : ''
    );
}