import React, { useState, useEffect } from 'react';
import { PlusLg, DashLg, X, PlusCircle, DashCircle } from 'react-bootstrap-icons';
import { Link, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { BASE_ROUTE, PAYMENT_LIST, SESSION_EXPIRED_CODE } from "../../config";
import { http_request } from "../../connection";
import { logout, numberToCash } from "../../lib";
import './style.css';
import { Form, Modal } from 'react-bootstrap';
import LoadingTip from '../../components/loading_tip';
import CartProgressChart from '../../components/cart_progress_chart/cart_progress_chart';
import Toast from '../../components/toast';

function Cart(){
    const navigate = useNavigate();
    const location = useLocation();
    const url_params = useParams();
    const { loadCart, shopping_cart } = useOutletContext();
    const [cart, setCart] = useState(shopping_cart);
    const [cart_tip, setCartTip] = useState('');
    const [toast_in, showToast] = useState(false);
    const [is_loading, setLoadingProgress] = useState(false);
    const [coupon_code, setCouponCode] = useState('');//用戶拿 coupon code 去換 coupon id
    const [coupon, setCoupon] = useState({});
    const [remove_tip_show, showRemoveTip] = useState(false);
    const [selected_item_index, selectItemIndex] = useState(null);
    const [stock_tip, showStockTip] = useState(false);
    const [payment, setPayment] = useState(url_params.payment || PAYMENT_LIST[0])
    const [cart_cache, setCartCache] = useState([]);

    useEffect(() => {
        setCart(shopping_cart);
        setCache(shopping_cart);
    }, [shopping_cart]);
    

    function deleteItem(){
        console.log(selected_item_index);
        if(selected_item_index === null) return;
        modifyCount(selected_item_index, (-1 * cart[selected_item_index].quantity));
        showRemoveTip(false);
        selectItemIndex(null);
    }
    function modifyCount(i, diff){
        if(is_loading) return;
        const item = cart[i];
        const quantity = item.quantity + diff;
        var method, params;

        if(quantity <= 0){//delete
            method = 'delete';
            params = {
                shopping_cart_id: cart[i].id
            };
        }else{//ediit
            method= 'edit';
            params = {
                quantity,
                shopping_cart_id: cart[i].id
            }
        }
        setLoadingProgress(true);
        http_request(`/shopping-cart/${method}`, 'POST', params).then(res=>{
            setLoadingProgress(false);
            if(res.msg == SESSION_EXPIRED_CODE){
                logout();
                return;
            }else{
                if(res.success){
                    loadCart();
                    let new_cart = [...cart];
                    new_cart[i].quantity = quantity;
                    if(quantity <= 0) new_cart.splice(i, 1);
                    setCart(new_cart);
                    // showTotalModifiedTip(true);
                }else{
                    setCartTip(Object.values(res.data));
                    showToast(true);
                }
            }
        });
    }
    function checkPrice(){//準備結帳之前先確認購物車裡的品項是否還有庫存及價錢是否有變動

    }
    function getCoupon(){
        let params = {
            code: coupon_code
        };
        setLoadingProgress(true);
        http_request(`/coupon/get-by-code`, 'POST', params).then(res=>{
            setLoadingProgress(false);
            if(res.msg == SESSION_EXPIRED_CODE){
                logout();
                return;
            }else{
                if(res.success){
                    setCoupon(res.data.coupon);
                    setCouponCode('');
                }else{
                    setCartTip(Object.values(res.data));
                    showToast(true);
                }
            }
        });
    }
    function removeCoupon(){
        setCoupon({});
    }
    function calcDiscount(){
        let discount_by_item = cart.map(item=>((item.product.price - item.product.discount_price) * item.quantity)).reduce(function(p, c){ return p + c; }, 0);
        let discount_by_coupon = (coupon.discount || 0);
        return discount_by_item + discount_by_coupon;
    }
    function setCache(new_cart){
        let c = new_cart || cart;
        localStorage.setItem('cart_cache', JSON.stringify(c));
    }
    return (<div 
        id="page-cart" 
        className="page-content d-flex flex-column pt-16"
    >
        <LoadingTip is_loading={is_loading}/>
        <Toast children={cart_tip} toast_in={toast_in} showToast={showToast}/>
        <Modal
            show={remove_tip_show}
            onHide={()=>{ showRemoveTip(false); }}
            centered
            className='p-16'
        >
            <div id="page-cart-remove-tip-container" className='p-16 cart-page-modal-container'>
                <div className='text'>Bạn có muốn xóa mục này không?</div>
                <div className='button-group'>
                    <button type='button' className='button light' onClick={()=>{
                        selectItemIndex(null);
                        showRemoveTip(false);
                    }}>dự trữ</button>
                    <button type='button' className='button dark' onClick={()=>{
                        deleteItem();
                    }}>Di dời</button>
                </div>
            </div>
        </Modal>
        <Modal
            show={stock_tip}
            onHide={()=>{ 
                selectItemIndex(null);
                showStockTip(false);
            }}
            centered
            className='p-16'
        >
            <div id="page-stock-tip-container" className='p-16 cart-page-modal-container'>
                <div className='text'>Vui lòng loại bỏ các mặt hàng hết hàng khỏi giỏ hàng của bạn</div>
                <div className='button-group'>
                    <button type='button' className='button light' onClick={()=>{
                        selectItemIndex(null);
                        showStockTip(false);
                    }}>dự trữ</button>
                    <button type='button' className='button dark' onClick={()=>{
                        selectItemIndex(null);
                        deleteItem();
                        showStockTip(false);
                    }}>Di dời</button>
                </div>
            </div>
        </Modal>
        <CartProgressChart step={1} />
        {
            cart.length ? 
            <div className='d-flex flex-column flex-grow-1'>
                <div className='mt-16 overflow-auto flex-grow-1'>
                    <div className='mx-16'>
                        <div className='list'>
                            {
                                cart.map((item, i)=>{
                                    return <div key={i} className={`item d-flex ${(item.product.stock > 0) ? '' : 'invalid'}`}>
                                        <div className='img-container'>
                                            {
                                                (item.product && item.product.images && item.product.images.length) ? <img className='product-picture' src={ item.product.images[0]} /> : ''
                                            }
                                        </div>
                                        <div className='item-body flex-column'>
                                            <div className='title'>
                                                <Link to={`${BASE_ROUTE}/product/${item.product.id}`}><span>{item.product.name}</span></Link>
                                                <span className='close-button-container' onClick={()=>{ 
                                                    selectItemIndex(i);
                                                    showRemoveTip(true);
                                                }}>
                                                    <img style={{ width: '16px', height: '16px' }} src={`${process.env.PUBLIC_URL}/assets/icon-button-delete-normal.svg`} />
                                                </span>
                                            </div>
                                            <div className='price-container'>
                                                <span className='price-discount'>${item.product.discount_price}</span>
                                                {
                                                    item.product.discount_price < item.product.price ? <span className='price'>${item.product.price}</span> : ''
                                                }
                                            </div>
                                            <div className='d-flex flex-grow-1 align-items-end justify-content-between'>
                                                <span className='count-selector'>
                                                    <span className="button" onClick={()=>{ if(item.quantity > 1) modifyCount(i, -1); }} ><DashCircle fill={item.quantity <= 1 ? 'rgb(219, 219, 219)' : '#404040'} size={20} /></span>
                                                    <span className='count'>
                                                        {
                                                            (item.product.stock > 0) ? item.quantity : 'Hết hàng'
                                                        }
                                                    </span>
                                                    <span className="button" onClick={()=>{ modifyCount(i, 1); }} ><PlusCircle size={20} /></span>
                                                </span>
                                                <span className='sub'>${item.product.discount_price * item.quantity}</span>
                                            </div>
                                        </div>
                                    </div>;
                                })
                            }
                        </div>
                        <div className='coupon-container block'>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className='fw-bold'>phiếu giảm giá</Form.Label>
                                    <div className='d-flex flex-row'>
                                        <Form.Control type="text" className='flex-grown-1' placeholder="Nhập mã giảm giá" value={coupon_code} onChange={e=>{
                                            setCouponCode(e.target.value);
                                        }} />
                                        <div className='coupon-button' onClick={()=>{ getCoupon(); }}>sử dụng</div>
                                    </div>
                                    {
                                        coupon.id ? <div className='coupon p-2 d-flex align-items-center'>
                                            <span className='flex-grow-1'>{coupon.name}</span>
                                            <div className='remove-coupon-button' onClick={()=>{ removeCoupon(); }}><X size={22}/></div>
                                        </div> : ''
                                    }
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="block mb-16 payment-block">
                            <div className='title'>Phương thức thanh toán<i className='requirenote' /></div>
                            <Form.Select 
                                aria-label='Phương thức thanh toán' 
                                name='payment'
                                value={payment}
                                onChange={e=>{
                                    setPayment(e.currentTarget.value);
                                }}
                                style={{
                                    marginBottom: '4px'
                                }}
                            >
                                {
                                    PAYMENT_LIST.map((opt, i)=><option value={opt} key={i}>{opt}</option>)
                                }
                            </Form.Select>
                        </div>
                    </div>
                    <div className='summary-container block mx-16'>
                        <div className='title underline'>Số tiền đặt hàng</div>
                        <div className='content'>
                            <div className='underline mb-12 pb-12'>
                                <div className='content-row'>
                                    <span>Tổng phụ：</span>
                                    <span className='text-end'>NT${ cart.map(item=>{ return item.product.price * item.quantity; }).reduce(function(p, c){ return p + c; }, 0) }</span>
                                </div>
                                {
                                    calcDiscount() ? <div className='content-row'>
                                        <span>Giảm giá：</span>
                                        <span>-NT{numberToCash(calcDiscount())}</span>
                                    </div> : ''
                                }
                                <div className='content-row'>
                                    <span>vận chuyển hàng hóa：</span>
                                    <span>NT{numberToCash(0)}</span>
                                </div>
                            </div>
                            <div className='sum'>
                                <div className='content-row'>
                                    <span>tổng cộng：</span>
                                    <span>NT{ numberToCash(cart.map(item=>{ return item.product.discount_price * item.quantity; }).reduce(function(p, c){ return p + c; }, 0) - (coupon.id ? coupon.discount : 0)) }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='foot p-16'>
                    <span className='sum-label'>tổng cộng：<span className='sum'>{ numberToCash(cart.map(item=>{ return item.product.discount_price * item.quantity; }).reduce(function(p, c){ return p + c; }, 0) - (coupon.id ? coupon.discount : 0)) }</span></span>
                    <span className='go-checkout-button' onClick={()=>{
                        for(let i in cart){
                            if(cart[i].product.stock <= 0){
                                selectItemIndex(i);
                                showStockTip(true);
                                return;
                            }
                        }
                        navigate(`${BASE_ROUTE}/checkout/${payment}` + ( coupon.id ? `/${coupon.id}` : '' ));
                    }}>
                        <span style={{ padding: 0 }}>Thanh toán</span>
                    </span>
                </div>
            </div> : <div className='d-flex flex-column align-items-center justify-content-start flex-fill bg-light' >
                <img className='tip-img-empty' src={`${process.env.PUBLIC_URL}/assets/cart_empty.svg`} />
                <span className='cart-tip-empty user-select-none'>Giỏ hàng hiện đang trống</span>
                <Link to={`${BASE_ROUTE}/`} className="go-home-button">
                    <span>Đi mua sắm</span>
                </Link>
            </div>
        }
    </div>);
}
export default Cart;