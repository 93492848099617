export const AUTO_FILLS = ['name', 'sku', 'detail_description', 'short_description'];
export function autoFill(p){
    AUTO_FILLS.forEach(c=>{
        if(p[c] === null) {
            p[c] = '-';
            // p[c] = `根據Deadline網站的報導，Netflix取消真人影集《惡靈古堡》第二季，原因無他，就是這部影集上映的這一個半月以來，並沒有太亮眼的收視表現。報導指出雖然開播的第一週受到新一季的《快奇物語》的影響，觀看時間為當週第二。            在觀眾的口耳相傳下，第二週也保持不錯的觀看時間。但在第三週後，就迅速從排行榜消失，跌出前十名。再爛番茄網站無論是媒體還是觀眾的評分雙雙給予低分，尤其觀眾部分創下《惡靈古堡》系列新低。在無法創造好的收視，外加遭受觀眾唾棄，Netflix取消第二季也能夠理解。`;
        }
    });
    p['short_description'] = p['short_description'].replaceAll(["\n", "\t"]);
    return p;
}

export default { autoFill };