import './style.css';
import { Link, useOutletContext } from "react-router-dom";
import CartProgressChart from "../../components/cart_progress_chart/cart_progress_chart";
import { BASE_ROUTE, ORDER_STATUS_LIST } from "../../config";
import { createTimeString, numberToCash } from '../../lib';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from '../../components/toast';
import React, { useState } from 'react';

export function Result(){
    const context = useOutletContext();
    const { result, order, code, msg } = context.order_result || {};

    return (<div id="page-result" className="page-content d-flex flex-column p-16">
        <CartProgressChart step={3} />
        {
            result ? <SuccessPannel order={order} /> : <ErrorPanel msg={msg} />
        }
    </div>);
}

function SuccessPannel(props){
    let { order } = props;
    return <div>
        <div className='res-content mt-16 col-12 col-sm-6 offset-sm-3 py-20 mb-16'>
            <div className='d-flex align-items-center justify-content-center pb-16'>
                <img src={`${process.env.PUBLIC_URL}/assets/cart_success.svg`} />
            </div>
            <div className='tip underline px-16 pb-20'>
                <span className='tip-text'>Đơn hàng được gửi thành công</span>
                <img className='tip-icon' src={`${process.env.PUBLIC_URL}/assets/icon-display-success.svg`} />
            </div>
            <div className='data-table p-16'>
                <div className='data-row'>
                    <label>số thứ tự：</label>
                    <span>{order.id}</span>
                </div>
                <div className='data-row'>
                    <label>ngày đặt hàng：</label>
                    <span>{createTimeString('y/m/d', new Date(order.datetime))}</span>
                </div>
                <div className='data-row'>
                    <label>tổng số tiền：</label>
                    <span>{numberToCash(order.total || 0)}</span>
                </div>
                <div className='data-row'>
                    <label>Phương thức thanh toán：</label>
                    <span>{order.payment}</span>
                </div>
                <div className='data-row'>
                    <label>Trạng thái đơn hàng：</label>
                    <span>{(ORDER_STATUS_LIST[order.status] || {}).name || '--'}</span>
                </div>
            </div>
            <div className='button-group px-16'>
                <Link className='d-flex' to={`${BASE_ROUTE}/order/${order.id}`}><button type='button' className='dark flex-grow-1'>Xem đơn hàng</button></Link>
                <Link className='d-flex' to={`${BASE_ROUTE}/`}><button type='button' className='mono-button light flex-grow-1'>Tiếp tục mua sắm</button></Link>
            </div>
        </div>
        {
            order.payment === 'Chuyển mạng' ? <PaymentInfo order={order} /> : ''
        }
    </div>;
}

function ErrorPanel(props){
    let { msg } = props;
    return <div className='res-content mt-16 col-12 col-sm-6 offset-sm-3 py-20'>
        <div className='d-flex align-items-center justify-content-center pb-16'>
            <img src={`${process.env.PUBLIC_URL}/assets/image-error-purchase.svg`} />
        </div>
        <div className='tip px-16'>
            <span className='tip-text'>Không thể gửi đơn đặt hàng</span>
            <img className='tip-icon' src={`${process.env.PUBLIC_URL}/assets/icon-display-error.svg`} />
        </div>
        <div className='pt-16 text-center mb-12'>{msg || ''}</div>
        <div className='d-flex flex-column px-16 pt-12'>
            <Link to={`${BASE_ROUTE}/cart`} className="d-flex mb-12">
                <button type='button' className='mono-button button dark flex-grow-1'>Quay lại giỏ hàng</button>
            </Link>
            <Link to={`${BASE_ROUTE}/`} className="d-flex">
                <button type='button' className='mono-button button light flex-grow-1'>Tiếp tục mua sắm</button>
            </Link>
        </div>
    </div>;
}

function PaymentInfo(props){
    let { order } = props;
    const [toast_in, showToast] = useState(false);
    return <React.Fragment>
        <Toast children={<span>
            <img src={`${process.env.PUBLIC_URL}/assets/icon-toast-success.svg`} />
            <span>已複製</span>
        </span>} toast_in={toast_in} showToast={showToast}/>
        <div className='block pt-12 mb-16 payment-info  col-12 col-sm-6 offset-sm-3'>
            <div className='underline fw-bold pb-12'>Thông tin thanh toán</div>
            <div className='underline fw-bold py-12 bank-info'>
                <img src={`${process.env.PUBLIC_URL}/assets/chinatrust-logo.svg`} />
                <span>VCB</span>
            </div>
            <div className='underline py-12'>
                <div className='label'>Tên tài khoản</div>
                <div className='strong'>CTY TNHH TANG TRUONG TC VN</div>
            </div>
            <div className='underline py-12 account'>
                <div className='label'>Số tài khoản</div>
                <div className='strong d-flex align-items-center justify-content-between'>
                    <span>
                        <span className='account-code'>1049926666</span>
                    </span>
                    <CopyToClipboard className='copy-button' text={'4383800066429522'} onCopy={()=>{ showToast(true); }}>
                        <img src={`${process.env.PUBLIC_URL}/assets/icon-button-copy-normal.svg`} />
                    </CopyToClipboard>
                </div>
            </div>
            <div className='underline py-12'>
                <div className='label'>Số tiền thanh toán</div>
                <div className='strong'>
                    NT{numberToCash((order.order_details || []).reduce(function(p, c){
                        return p + (c.discount_price * c.quantity);
                    }, 0) + order.delivery_fee)}
                </div>
            </div>
            <div className='underline py-12 expiration'>
               <div className='label'>thời hạn thanh toán</div>
                    <div className='strong'>Hết hạn lúc 23:14 ngày 8 tháng 8 năm 2024</div>
                    <div className='warning'>Còn lại 2 ngày, 23 giờ và 59 phút</div>
                    <div className='warning'>*Nếu quá thời hạn thanh toán không được thực hiện, đơn hàng sẽ tự động bị hủy.</div>
            </div>
            <div className='pt-12'>
                <div className='label'>注意事項</div>
                <ol className='ps-12 mb-0 tips'>
                    <li>Vui lòng sử dụng ngân hàng trực tuyến hoặc ATM vật lý để chuyển số tiền vào tài khoản trên.</li>
                        <li>Bạn có thể kiểm tra trạng thái thanh toán trên trang đặt hàng 30 phút sau khi quá trình chuyển khoản hoàn tất.</li>
                        <li>Vui lòng giữ lại biên lai hóa đơn cho đến khi khoản thanh toán được ghi có. Nếu khoản thanh toán không được ghi có sau 48 giờ kể từ khi chuyển khoản, vui lòng liên hệ lại với chúng tôi để được giải đáp.</li>
                </ol>
            </div>
        </div>
    </React.Fragment>;
}
export default Result;