import './style.css';
import { useState } from "react";
import { FormControl } from "react-bootstrap";
import LoadingTip from '../../components/loading_tip';
import { http_request } from '../../connection';
import { useNavigate } from 'react-router-dom';
import { BASE_ROUTE } from '../../config';

export default function Register(){
    const navigate = useNavigate();
    const [is_loading, setLoadingProgress] = useState(false);
    const [account, setAccount] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [error_message, setErrorMessage] = useState('　');

    function register(){
        if(is_loading) return;
        let params = { account, password, name };
        setLoadingProgress(true);
        http_request(`/customer/register`, 'POST', params).then(res=>{
            setLoadingProgress(false);
            if(res.success){
                setErrorMessage('　');
                navigate(`${BASE_ROUTE}/login`);
            }else{
                setErrorMessage(res?.msg || 'Đăng ký không thành công');
            }
        });
    }

    return (<div id="page-login" className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
        <LoadingTip is_loading={is_loading} />
        <div id="form-container" className='mb-5'>
            <h2>Đăng ký tài khoản</h2>
            <div className="p-3 mt-2 w-100 mb-5">
                <FormControl
                    className="py-1 px-2 fs-14"
                    placeholder="số tài khoản"
                    value={account}
                    onChange={e=>{ setAccount(e.currentTarget.value); }}
                    label="số tài khoản"
                    type='account'
                />
                <FormControl
                    className="mt-3 py-1 px-2 fs-14"
                    placeholder="mật khẩu"
                    value={password}
                    onChange={e=>{ setPassword(e.currentTarget.value); }}
                    label="mật khẩu"
                    type='password'
                />
                <FormControl
                    className="mt-3 py-1 px-2 fs-14"
                    placeholder="biệt danh"
                    value={name}
                    onChange={e=>{ setName(e.currentTarget.value); }}
                    label="biệt danh"
                />
                <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                    <div className='text-danger mb-2'>{error_message}</div>
                    <button className="default-button dark" type="button" style={{ width: 120 }} onClick={()=>{ register(); }}>đăng ký</button>
                </div>
            </div>
        </div>
    </div>);
}
