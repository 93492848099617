Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
export function jumpToLineEntry(){
    window.location.href = '/login';
    console.log('token invalid');
}
export function removeToken(){
    localStorage.removeItem('shestylee');
}
export function logout(){
    console.log(`logout`);
    removeToken();
    jumpToLineEntry();
}
export function createTimeObject(raw) {
    let r = raw ? new Date(raw) : new Date();
    let y = r.getFullYear();
    let m = ("00" + (r.getMonth() + 1)).substr(-2);
    let d = ("00" + r.getDate()).substr(-2);
    let h = ("00" + r.getHours()).substr(-2);
    let i = ("00" + r.getMinutes()).substr(-2);
    let s = ("00" + r.getSeconds()).substr(-2);
    return { y, m, d, h, i, s };
}
export function createTimeString(format = '', raw = undefined) {
    let datetime_object = createTimeObject(raw);
    let output = '';
    for (let i in format) {
        let char = format[i];
        output += datetime_object[char] || char;
    }
    return output;
}
export function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}
export function numberWithComma(number = 0) {
    if (isNaN(number)) {
        console.warn('not a numeric arg');
        return '$0';
    }
    let s = number.toString();
    let f = s.replace(/(\.+\d+)$/, '');
    let b = s.replace(/^(\d+)/, '');
    let output = '';
    let d = 0;
    for (let i = f.length - 1; i >= 0; i--) {
        d++;
        let c = f[i];
        output = c + output;
        if (d > 0 && d % 3 == 0 && d !== f.length) output = ',' + output;
    }
    return `${output}${b}`;
}
export function numberToCash(number = 0) {
    return '$' + numberWithComma(number);
}

export function calculateDiscount(product){
    let q1 = (product.price == 0);
    let q2 = (product.discount_price == 0);
    let q3 = (product.price == product.discount_price);
    let q4 = (!product.discount_price || !product.price);
    let q5 = (!product.product_discount);
    if(q1 || q2 || q3 || q4 || q5){
        product.discount_rate = null;
        product.discount_expiration = new createTimeObject();
    }else{
        product.discount_rate = (product.discount_price / product.price).toFixed(2) * 100;
        if(product.discount_rate > 10 && (product.discount_rate % 10 === 0)) product.discount_rate /= 10;
        product.discount_expiration = new createTimeObject(product.product_discount.end_date);
    }
    return product;
}
export function bySort(a, b){
    if(a.sort > b.sort) return 1;
    if(a.sort === b.sort) return 0;
    return -1;
}

export default { jumpToLineEntry, removeToken, logout, createTimeObject, createTimeString, getDates, numberWithComma, numberToCash, bySort };