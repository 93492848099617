import './style.css';

export default function CartProgressChart(props){
    return <div id="cart-progress-chart" className='steps-container'>
        <span className='progress-bar'></span>
        <span className='step-group'>
            <span className={ 'step' + (props.step == 1 ? ' active' : '')}>
                <div className='circle'><span>1</span></div>
                <label>giỏ hàng</label>
            </span>
            <span className={ 'step' + (props.step == 2 ? ' active' : '')}>
                <div className='circle'><span>2</span></div>
                <label>Điền thông tin</label>
            </span>
            <span className={ 'step' + (props.step == 3 ? ' active' : '')}>
                <div className='circle'><span>3</span></div>
                <label>Xác nhận</label>
            </span>
        </span>
    </div>;
}