export function NumberButton(props){
    const Icon = props.icon;
    let { disabled, fill } = props;
    return  <span className={'button-bounding-box ' + (props.className || '')} onClick={disabled ? function(){} : props.onClick}>
        <span 
            className='d-flex align-items-center justify-content-center number-button'
            style={{
                color: disabled ? 'rgb(210,210,210)' : (fill || '#404040')
            }}
        >
            <Icon />
        </span>
    </span>
}

export default { NumberButton };