import { useEffect } from "react";
import { Fade } from "react-bootstrap";

export default function Toast(props){
    let { children, delay, toast_in, showToast } = props;
    if(!delay) delay = 3000;
    var max_stamp = null;

    useEffect(()=>{
        if(toast_in){
            max_stamp = Date.now() + delay;
            setTimeout(()=>{
                let now = Date.now();
                if(now >= max_stamp){
                    showToast(false);
                }
                console.log(`now: ${now}, max_stamp: ${max_stamp}`);
            }, delay);
        }
    }, [toast_in]);

    return <Fade in={toast_in}>
        <div className='round-toast'>{children}</div>
    </Fade>;
}