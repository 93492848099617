import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/icon-button-check-success.svg';
import './assets/icon-button-check-default.svg';
import './index.css';
import App from './App';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { BASE_ROUTE } from './config';
import Account from './routes/account';
import Cart from './routes/cart';
import OrderList from './routes/order_list';
import Login from './routes/login';
import Product from './routes/product';
import Gallery from './routes/gallery';
import Checkout from './routes/checkout';
import Result from './routes/result';
import Order from './routes/order';
import BillSettings from './routes/bill_settings';
import Register from './routes/register';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path={`${BASE_ROUTE}/login`} element={<Login />} />
      <Route path={`${BASE_ROUTE}/register`} element={<Register />} />
      <Route path={`${BASE_ROUTE}/`} element={<App />}>
        <Route path={`${BASE_ROUTE}/`} element={<Gallery />} />
        <Route path={`${BASE_ROUTE}/gallery/:category`} element={<Gallery />} />
        <Route path={`${BASE_ROUTE}/product/:id`} element={<Product />} />
        <Route path={`${BASE_ROUTE}/account`} element={<Account />}>
          <Route path={`${BASE_ROUTE}/account/bill_settings`} element={<BillSettings />} />
          <Route path={`${BASE_ROUTE}/account/order_list`} element={<OrderList />} />
        </Route>
        <Route path={`${BASE_ROUTE}/cart`} element={<Cart />} />
        <Route path={`${BASE_ROUTE}/cart/:payment`} element={<Cart />} />
        <Route path={`${BASE_ROUTE}/order/:id`} element={<Order />} />
        <Route path={`${BASE_ROUTE}/checkout/:payment`} element={<Checkout />} />
        <Route path={`${BASE_ROUTE}/checkout/:payment/:coupon_id`} element={<Checkout />} />
        <Route path={`${BASE_ROUTE}/result`} element={<Result />} />
      </Route>
      <Route path="*" element={<Navigate to={`${BASE_ROUTE}/`} replace />} />
    </Routes>
  </BrowserRouter>
);
