export const DEBUG = 0;
export const PRODUCT = 1;
export const ENV = DEBUG;
export const BASE_ROUTE = '';
export const DOMAIN = ENV === DEBUG ? 'api.shestylee.com' : 'api.shestylee.com';
export const PROTOCOL = 'https';
export const API_ADDR = `${PROTOCOL}://${DOMAIN}/frontend`;
export const ROOT_ADDR = `${PROTOCOL}://${DOMAIN}`;
export const SESSION_EXPIRED_CODE = "Unauthenticated.";
export const TOKEN_FAILED_CODE = '無效的code';
export const API_STATE = 'web';
export const LINE_ENTRY = `${PROTOCOL}://${DOMAIN}/frontend/customer/login?state=${API_STATE}`;
export const PAGE_SIZE = window.screen.height ? Math.ceil(window.screen.height / 100) : 1;
export const ORDER_STATUS_LIST = {
    pending_payment: {
        key: 'pending_payment',
        name: 'Đã nhận hàng, chờ thanh toán'
    },
    processing: {
        key: 'processing',
        name: 'Đơn hàng đã được nhận và đang được xử lý'
    },
    on_hold: {
        key: 'on_hold',
        name: 'dự trữ'
    },
    completed: {
        key: 'completed',
        name: 'Hoàn thành'
    },
    cancelled: {
        key: 'cancelled',
        name: 'Hủy bỏ'
    },
    refounded: {
        key: 'refounded',
        name: 'Đã hoàn tiền'
    },
    failed: {
        key: 'failed',
        name: 'thất bại'
    }
};
export const PAYMENT_LIST = [
    'tiền mặt khi giao hàng',
    'thẻ tín dụng',
    'Chuyển mạng',
    'LINE PAY'
];

export const EXTEND = 0;
export const REPLACE = 1;


export const COUNTRY_DEFAULT_VALUE = 'Việt Nam';
export const DISTRICT_DEFAULT_VALUE = '';
export const CITY_DEFAULT_VALUE = '';

export const TAB_ADDRESS_SETTINGS = 0;
export const TAB_MY_ORDERS = 1;

export const TYPE_BILL = 'bill';
export const TYPE_SHIPPING = 'shipping';
export const BILL_FORM_ITEMS = [{
    label: 'Họ',
    name: 'last_name',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: 'tên',
    name: 'name',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: 'công ty',
    name: 'company',
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: 'Số liên lạc',
    name: 'contact_number',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: 'Địa chỉ1',
    name: 'address_1',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: 'Địa chỉ2',
    name: 'address_2',
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: 'Thành phố',
    name: 'city',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: 'mã bưu chính',
    name: 'postal_code',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
}/*,{
    label: '國家',
    name: 'country',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
}*/,{
    label: 'Nhận xét',
    name: 'remark',
    scope: [TYPE_BILL]
},{
    label: 'Tên cửa hàng',
    name: 'shipping_purchaser_store',
    scope: [TYPE_SHIPPING]
},{
    label: 'Lưu trữ số điện thoại',
    name: 'shipping_purchaser_phone',
    scope: [TYPE_SHIPPING]
},{
    label: 'mã cửa hàng',
    name: 'shipping_purchaser_code',
    scope: [TYPE_SHIPPING]
}];

export default {
    DEBUG,
    PRODUCT,
    ENV,
    API_ADDR, 
    ROOT_ADDR,
    BASE_ROUTE,
    CITY_DEFAULT_VALUE,
    COUNTRY_DEFAULT_VALUE, 
    DISTRICT_DEFAULT_VALUE,
    EXTEND,
    REPLACE,
    BILL_FORM_ITEMS,
    TAB_ADDRESS_SETTINGS,
    TAB_MY_ORDERS,
    TYPE_BILL, 
    TYPE_SHIPPING, 
    SESSION_EXPIRED_CODE, 
    TOKEN_FAILED_CODE, 
    LINE_ENTRY, 
    ORDER_STATUS_LIST,
    PAYMENT_LIST
};