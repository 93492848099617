import taiwan_districts from '../../assets/taiwan_districts.json';
import './style.css';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { FormGroup, Modal, Toast } from "react-bootstrap";
import { BASE_ROUTE, CITY_DEFAULT_VALUE, COUNTRY_DEFAULT_VALUE, DISTRICT_DEFAULT_VALUE, PAYMENT_LIST, SESSION_EXPIRED_CODE, TYPE_BILL, TYPE_SHIPPING } from "../../config";
import { http_request } from "../../connection";
import { logout } from "../../lib";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import LoadingTip from '../../components/loading_tip';
import CartProgressChart from '../../components/cart_progress_chart/cart_progress_chart';



const FORM_ITEMS = {
    order: {
        payment: {
        label: 'Phương thức thanh toán',
        name: 'payment',
        options: PAYMENT_LIST
    },
    remark: {
        label: 'Nhận xét',
        name: 'remark',
    }},
    bill_info: [{
            label: 'Tên người nhận',
            name: 'name',
            required: true
        },{
            label: 'Số điện thoại người nhận',
            name: 'contact_number',
            required: true
        },{
            label: 'Địa chỉ thanh toán 1',
            name: 'address_1',
            required: true
        },{
            label: 'Địa chỉ thanh toán 2',
            name: 'address_2'
        },{
            label: 'thành phố hóa đơn',
            name: 'city',
            required: true
        },{
            label: 'Mã zip thanh toán',
            name: 'postal_code',
            required: true
        },{
            label: 'quốc gia thanh toán',
            name: 'country',
            required: true,
            hide: true,
            default_value: COUNTRY_DEFAULT_VALUE
        },{
            label: 'Ghi chú thanh toán',
            name: 'remark'
        },{
            label: 'Số thống nhất',
            name: 'tax_id'
    }],
    shipping_info: [{
        label: 'Họ tên đơn hàng vận chuyển',
        name: 'last_name',
        required: true
    },{
        label: 'Tên vận đơn',
        name: 'name',
        required: true
    },{
        label: 'Tên công ty vận chuyển',
        name: 'company'
    },{
        label: 'Số liên lạc đặt hàng vận chuyển',
        name: 'contact_number',
        required: true
    },{
        label: 'Địa chỉ đặt hàng vận chuyển 1',
        name: 'address_1',
        required: true
    },{
        label: 'Địa chỉ đặt hàng vận chuyển 2',
        name: 'address_2'
    },{
        label: 'Thành phố đặt hàng vận chuyển',
        name: 'city',
        required: true
    },{
        label: 'Mã bưu điện đơn hàng vận chuyển',
        name: 'postal_code',
        required: true
    },{
        label: 'Quốc gia đặt hàng vận chuyển',
        name: 'country',
        required: true,
        hide: true,
        default_value: COUNTRY_DEFAULT_VALUE
    },{
        label: 'Tên cửa hàng đặt hàng vận chuyển',
        name: 'shipping_purchaser_store'
    },{
        label: 'Số điện thoại cửa hàng vận chuyển',
        name: 'shipping_purchaser_phone'
    },{
        label: 'Mã cửa hàng đặt hàng vận chuyển',
        name: 'shipping_purchaser_code'
}]
};

export function Checkout(){
    const location = useLocation();
    const navigate = useNavigate();
    const url_params = useParams();
    const { setOrderResult, form_cache, setFormCache } = useOutletContext();
    var { coupon_id } = url_params;
    const { payment } = url_params;
    const [validated, setValidated] = useState(false);
    const [bill_info_sample, setBillInfoSample] = useState({});
    const [shipping_info_sample, setShippingInfoSample] = useState({});
    const [toast_visibility, setToastVisibility] = useState(false);
    const [error_text, setErrorText] = useState('');
    const [confirm_box_visibility, setConfimBoxVisibility] = useState(false);
    const [is_loading, setLoadingProgress] = useState(false);
    const [total_modified_tip, showTotalModifiedTip] = useState(false);
    const [bill_info_check, checkBillInfo] = useState(false);
    const [shipping_info_check, checkShippingInfo] = useState(false);
    const [copy_check, checkCopy] = useState(false);
    const [stock_treatment, setStockTreatment] = useState('filter');
    const [form_data, setFormData] = useState({
        bill_info: {
            city: '',
            district: ''
        },
        shipping_info: {
            city: '',
            district: ''
        },
        payment: payment || FORM_ITEMS.order.payment.options[0],
        remark: ''
    });
    const toggleToast = ()=>{ setToastVisibility(!toast_visibility); };

    useEffect(()=>{
        if(form_cache){
            recoverForm();
        }else{
            loadSamples();
        }
        setFormCache(null);
    }, [location]);
    useEffect(()=>{
        let q1 = confirm_box_visibility;
        let q2 = form_data.bill_info.city === CITY_DEFAULT_VALUE;
        let q3 = form_data.bill_info.district === DISTRICT_DEFAULT_VALUE;
        let q4 = form_data.shipping_info.city === CITY_DEFAULT_VALUE;
        let q5 = form_data.shipping_info.district === DISTRICT_DEFAULT_VALUE;
        if(q1 || q2 || q3 || q4 || q5) return;
        clearForm();
        navigate(`${BASE_ROUTE}/result`);
    }, [confirm_box_visibility]);


    useEffect(()=>{
        if(!form_cache) applySample('bill_info', bill_info_sample);
    }, [bill_info_sample]);
    useEffect(()=>{
        if(!form_cache) applySample('shipping_info', shipping_info_sample);
    }, [shipping_info_sample]);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        //檢查購物車跟時價有無差距
        let result = await checkMarketPrice();
        if(!result){
            showTotalModifiedTip(true);
            return;
        }

        setValidated(true);
        if(form.checkValidity()){
            let params = Object.assign({}, form_data);
            for(let p in params){
                if(params[p] === null) params[p] = '';
            }
            for(let p in params.bill_info){
                if(params.bill_info[p] === null) params.bill_info[p] = '';
                if(!FORM_ITEMS.bill_info.find(item=>item.name === p)) delete params.bill_info[p];
            }
            for(let p in params.shipping_info){
                if(params.shipping_info[p] === null) params.shipping_info[p] = '';
                if(!FORM_ITEMS.shipping_info.find(item=>item.name === p)) delete params.shipping_info[p];
            }
            params.stock_treatment = stock_treatment;
            if(coupon_id){
                params.coupon_id = coupon_id;
            }

            //國家鎖住
            params.bill_info.country = COUNTRY_DEFAULT_VALUE;
            params.shipping_info.country = COUNTRY_DEFAULT_VALUE;
            
            let getPostalCode = (form=>{
                let city = taiwan_districts.find(city=>city.name === form.city);
                if(!city) return null;
                let district = city.districts.find(district=>district.name === form.address_1);
                if(!district) return null;
                return district.zip;
            });
            params.bill_info.postal_code = getPostalCode(params.bill_info);
            params.shipping_info.postal_code = getPostalCode(params.shipping_info);

            console.log(params);
            http_request(`/shopping-cart/checkout`, 'POST', params).then(res=>{
                console.log(res);
                
                if(res.msg == SESSION_EXPIRED_CODE){
                    logout();
                    return;
                }else{
                    /**
                     * code === 1 表示表單錯誤但不用留下來修改
                     * success === true 表示下單成功
                     * success === false 表示表單錯誤並且讓用戶修改
                     */
                    if(res.success || res.code == 1){
                        if(bill_info_check){
                            saveAsSampleBill(TYPE_BILL);
                        }
                        if(shipping_info_check){
                            saveAsSampleBill(TYPE_SHIPPING);
                        }
                        let context = {
                            result: res.success,
                            order: res.data.order,
                            code: res.code,
                            msg: res.msg
                        };
                        setOrderResult(context);
                        navigate(`${BASE_ROUTE}/result`);
                    }else{
                        setErrorText(Object.values(res.data));
                        setToastVisibility(true);
                    }
                    
                }
            });
        }
        
    };
    function clearForm(){
        setFormData({
            bill_info: {
                city: '',
                district: ''
            },
            shipping_info: {
                city: '',
                district: ''
            },
            payment: FORM_ITEMS.order.payment.options[0],
            remark: ''
        });
    }
    function closeAndRedirect(){//假如送出結帳的時候發現時價跟購物車價格不同, 跳提示 modal, 記住 form data, 返回 /cart
        showTotalModifiedTip(false);
        setFormCache({
            form_data: Object.assign({}, form_data),
            coupon_id,
        });
        navigate(`${BASE_ROUTE}/cart/${payment}`);
    }
    function recoverForm(){//進入本頁的時候, 檢查有無快取可抓, 如果有, 更新, 並且把快取幹掉
        setFormData(Object.assign({}, form_cache.form_data));
        coupon_id = form_cache.coupon_id;
        setFormCache(null);
    }
    function loadSamples(){
        let loadSample = (type, setter, checker) => {
            let params = { type };
            http_request('/customer-bill-info/list', 'POST', params).then(res=>{
                if(res.msg == SESSION_EXPIRED_CODE){
                    logout();
                    return;
                }else{
                    if(res.success){
                        let list = res.data.customerBillInfos || [];
                        let sample = list.length ? list[0] : {};
                        for(let p in sample){
                            if(sample[p] == null) sample[p] = '';
                        }
                        sample.company = '';
                        setter(sample);
                        if(list.length === 0) checker(true);
                    }
                }
            });
        };
        loadSample('bill', setBillInfoSample, checkBillInfo);
        loadSample('shipping', setShippingInfoSample, checkShippingInfo);
    }
    function applySample(doc_name, sample){
        let new_form = Object.assign({}, form_data);
        if(sample){
            for(let p in sample){
                new_form[doc_name][p] = sample[p];
            }
        }else{
            new_form[doc_name] = {};
        }
        setFormData(new_form);
    }
    function popErrorTip(){
        setToastVisibility(true);
    }
    function saveAsSampleBill(sample_type){
        let params = {};
        let action = 'edit';
        let sample;
        switch(sample_type){
            case TYPE_BILL:
                params.type = 'bill';
                for(let p in form_data.bill_info){
                    params[p] = form_data.bill_info[p];
                }
                sample = Object.assign({}, bill_info_sample);
                break;
            case TYPE_SHIPPING:
                params.type = 'shipping';
                for(let p in form_data.shipping_info){
                    params[p] = form_data.shipping_info[p];
                }
                sample = Object.assign({}, shipping_info_sample);
                break;
        }
        if(!sample.id) {
            action = 'create';
            params.last_name = '-';
        }else{
            params.customer_bill_info_id = sample.id;
        }
        params.postal_code = (()=>{
            let city = taiwan_districts.find(city=>city.name === params.city);
            if(!city) return null;
            let district = city.districts.find(district=>district.name === params.address_1);
            if(!district) return null;
            return district.zip;
        })();
        http_request(`/customer-bill-info/${action}`, 'POST', params);
    }
    function loadCache(){
        let cache = localStorage.getItem('cart_cache') || '[]';
        try{
            cache = JSON.parse(cache);
        }catch(e){
            console.log('Không thể tải bộ đệm giỏ hàng');
            cache = [];
        }
        return cache;
    }
    async function comparePrice(cart_cache){
        if(!Array.isArray(cart_cache)){
            console.log('Định dạng nội dung bộ đệm không khớp');
            return true;
        }
        let flag = true;
        await Promise.all(cart_cache.map(async item=>{
            let current_price = await loadMarketPrice(item.product.id);
            if(item.product.discount_price != current_price)flag = false;
        }));
        return flag;
    }
    async function loadMarketPrice(product_id){
        let params = { product_id };
        let res = await http_request(`/product/get`, 'POST', params);
        if(!res.data || !res.data.product){
            console.log(`Những điểm bất thường được tìm thấy trong quá trình so sánh giá data`);
            console.log(res);
            return null;
        }
        return res.data.product.discount_price;
    }
    async function checkMarketPrice(){
        let cart_cache = loadCache();
        return await comparePrice(cart_cache);
    }
    return <div id="page-checkout" className="page-content d-flex flex-column p-16">
        <LoadingTip is_loading={is_loading} />
        <Toast 
            show={toast_visibility}
            onClose={toggleToast}
            position="bottom-end"
            delay={5000}
            autohide
            className="position-fixed"
            style={{zIndex: 99999, bottom: 10, left: 10}}
        >
            <Toast.Header>
                <span style={{ flex: 1 }}>Thanh toán không thành công</span>
            </Toast.Header>
            <Toast.Body>{error_text}</Toast.Body>
        </Toast>
        <Modal
            show={total_modified_tip}
            onHide={()=>{ closeAndRedirect(); }}
            centered
            className='p-16'
        >
            <div id="page-cart-total-tip-container" className='p-16 cart-page-modal-container'>
                <div className='text'>Số lượng sản phẩm trong giỏ hàng đã thay đổi</div>
                <div className='button-group d-flex'>
                    <button type='button' className='button dark flex-grow-1' onClick={()=>{
                        closeAndRedirect();
                    }}>Chắc chắn</button>
                </div>
            </div>
        </Modal>
        <Modal
            size="md"
            show={confirm_box_visibility}
            onHide={()=>{ 
                setConfimBoxVisibility(false);
            }}
        >
            <div className='card border-radius-5 p-5 text-center'>
                <h4>Có đặt thông tin thanh toán hiện tại làm mẫu hay không?</h4>
                <div className='d-flex justify-content-center mt-5'>
                    <Button className='me-5 px-5' variant='primary' onClick={()=>{ saveAsSampleBill(); }}>Đúng</Button>
                    <Button className='ns-5 px-5' variant='danger' onClick={()=>{ setConfimBoxVisibility(false); }}>KHÔNG</Button>
                </div>
            </div>
        </Modal>
        <CartProgressChart step={2} />
        <div className='mt-16 col-12 col-sm-6 offset-sm-3'>
            <Form noValidate validated={validated} className="d-flex flex-column flex-grow-1 bg-light" onSubmit={handleSubmit}>
                <div>
                    <div className="block mb-16">
                        <div className='title underline mb-12 pb-12 d-flex justify-content-between'>
                            <span>Thông tin vận chuyển hàng hóa<i className='requirenote' /></span>
                        </div>
                        <div className='underline pb-16'>
                            <Form.Group>
                                <Form.Label className='user-select-none' htmlFor={`shipping_info_name`}>Tên người nhận</Form.Label>
                                <Form.Control 
                                    id={`shipping_info_name`}
                                    type='text' 
                                    placeholder="Vui lòng điền tên thật của người nhận để đảm bảo việc nhận được suôn sẻ"
                                    name="name"
                                    required={true}
                                    onChange={e=>{
                                        let new_data = Object.assign({}, form_data);
                                        new_data.shipping_info.name = e.currentTarget.value;
                                        setFormData(new_data);
                                    }}
                                    value={form_data.shipping_info.name || ''}
                                />
                                <Form.Control.Feedback className='text-end' type="invalid">
                                    Tên người nhận không được để trống
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='user-select-none my-12' htmlFor={`shipping_info_contact_number`}>Số điện thoại người nhận</Form.Label>
                                <Form.Control 
                                    id={`shipping_info_contact_number`}
                                    type='text'
                                    placeholder="09XXXXXXXX"
                                    name="contact_number"
                                    required={true}
                                    onChange={e=>{
                                        let new_data = Object.assign({}, form_data);
                                        new_data.shipping_info.contact_number = e.currentTarget.value;
                                        setFormData(new_data);
                                    }}
                                    value={form_data.shipping_info.contact_number || ''}
                                />
                                <Form.Control.Feedback className='text-end' type="invalid">
                                    Số điện thoại người nhận không được để trống
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div>
                            <FormGroup>
                                <Form.Label className="user-select-none my-12">Địa điểm vận chuyển: Việt Nam</Form.Label>
                                <div className='d-flex mb-12'>
                                    <Form.Select 
                                        className='flex-grow-1 me-1'
                                        name="shipping_info_city"
                                        required
                                        value={form_data.shipping_info.city}
                                        onChange={e=>{
                                            let new_city = e.currentTarget.value;
                                            let new_data = Object.assign({}, form_data);
                                            new_data.shipping_info.city = new_city;
                                            new_data.shipping_info.district = (new_city === CITY_DEFAULT_VALUE) ? DISTRICT_DEFAULT_VALUE : taiwan_districts.find(city=>city.name === new_city).districts[0].name;
                                            setFormData(new_data);
                                        }}
                                        style={{
                                            marginBottom: '4px'
                                        }}
                                    >
                                        <option value="">Tỉnh</option>
                                        {
                                            taiwan_districts.map((city, i)=><option key={i} value={city.name}>{city.name}</option>)
                                        }
                                    </Form.Select>
                                    <Form.Select 
                                        className='flex-grow-1 ms-1'
                                        name="shipping_info_district"
                                        required
                                        value={form_data.shipping_info.address_1}
                                        onChange={e=>{
                                            let new_data = Object.assign({}, form_data);
                                            new_data.shipping_info.address_1 = e.currentTarget.value;
                                            setFormData(new_data);
                                        }}
                                        style={{
                                            marginBottom: '4px'
                                        }}
                                    >
                                        <option value="">thành phố</option>
                                        {
                                            (form_data.shipping_info.city === CITY_DEFAULT_VALUE) ? '' :
                                            ((taiwan_districts.find(city=>city.name === form_data.shipping_info.city) || { districts: [] }).districts || []).map((district, i)=><option key={i} value={district.name}>{district.name}</option>)
                                        }
                                    </Form.Select>
                                </div>
                                <Form.Control
                                    id={`shipping_info_address_2`}
                                    type='text'
                                    placeholder="Vui lòng nhập địa chỉ đầy đủ của bạn"
                                    name="address_2"
                                    required={true}
                                    onChange={e=>{
                                        let new_data = Object.assign({}, form_data);
                                        new_data.shipping_info.address_2 = e.currentTarget.value;
                                        setFormData(new_data);
                                    }}
                                    value={form_data.shipping_info.address_2 || ''}
                                />
                                <Form.Control.Feedback className='text-end' type="invalid">
                                    Địa chỉ giao hàng không được để trống
                                </Form.Control.Feedback>
                            </FormGroup>
                        </div>
                        <Form.Group className="mt-16">
                                <Form.Check>
                                    <Form.Check.Input
                                        id={`shipping_info_save_check`}
                                        type='checkbox'
                                        checked={shipping_info_check}
                                        value='shipping_info'
                                        onChange={e=>{ 
                                            let checked = shipping_info_check;
                                            checkShippingInfo(!checked);
                                        }}
                                    />
                                    <Form.Label htmlFor={`shipping_info_save_check`} className="user-select-none ms-1">Đặt thông tin mặc định cho lô hàng</Form.Label>
                                </Form.Check>
                        </Form.Group>
                    </div>
                    <div className="block mb-16">
                        <div className='title underline mb-12 pb-12 d-flex justify-content-between'>
                            <span>Thông tin thanh toán<i className='requirenote' /></span>
                            <Form.Group className="d-flex">
                                <Form.Check>
                                    <Form.Check.Input 
                                        id="copy_from_bill_info"
                                        type='checkbox'
                                        checked={copy_check}
                                        onChange={e=>{
                                            applySample('bill_info', null, '');
                                            let accepted = !copy_check;
                                            if(accepted){
                                                FORM_ITEMS.bill_info.forEach(row=>{
                                                    let new_data = Object.assign({}, form_data);
                                                    new_data.bill_info[row.name] = form_data.shipping_info[row.name] || '';
                                                    setFormData(new_data);
                                                });
                                            }else{
                                                FORM_ITEMS.bill_info.forEach(row=>{
                                                    let new_data = Object.assign({}, form_data);
                                                    new_data.bill_info[row.name] = '';
                                                    setFormData(new_data);
                                                });
                                            }
                                            checkCopy(!copy_check);
                                        }}
                                    />
                                </Form.Check>
                                <Form.Label htmlFor="copy_from_bill_info" className="user-select-none ms-1">Tương tự như trên</Form.Label>
                            </Form.Group>
                        </div>
                        <div className='underline pb-16'>
                            <Form.Group>
                                <Form.Label className='user-select-none' htmlFor={`bill_info_name`}>Tên người nhận</Form.Label>
                                <Form.Control 
                                    id={`bill_info_name`}
                                    type='text' 
                                    placeholder="Vui lòng điền tên thật của người nhận để đảm bảo việc nhận được suôn sẻ"
                                    name="name"
                                    required={true}
                                    onChange={e=>{
                                        let new_data = Object.assign({}, form_data);
                                        new_data.bill_info.name = e.currentTarget.value;
                                        setFormData(new_data);
                                    }}
                                    value={form_data.bill_info.name || ''}
                                />
                                <Form.Control.Feedback className='text-end' type="invalid">
                                    Tên người nhận không được để trống
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='user-select-none my-12' htmlFor={`bill_info_contact_number`}>Số điện thoại người nhận</Form.Label>
                                <Form.Control 
                                    id={`bill_info_contact_number`}
                                    type='text'
                                    placeholder="09XXXXXXXX"
                                    name="contact_number"
                                    required={true}
                                    onChange={e=>{
                                        let new_data = Object.assign({}, form_data);
                                        new_data.bill_info.contact_number = e.currentTarget.value;
                                        setFormData(new_data);
                                    }}
                                    value={form_data.bill_info.contact_number || ''}
                                />
                                <Form.Control.Feedback className='text-end' type="invalid">
                                    Tên người nhận không được để trống
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div>
                            <FormGroup>
                                <Form.Label className="user-select-none my-12">Địa điểm nhận: Việt Nam</Form.Label>
                                <div className='d-flex mb-12'>
                                    <Form.Select 
                                        className='flex-grow-1 me-1'
                                        name="bill_info_city"
                                        required
                                        value={form_data.bill_info.city}
                                        onChange={e=>{
                                            let new_city = e.currentTarget.value;
                                            let new_data = Object.assign({}, form_data);
                                            new_data.bill_info.city = new_city;
                                            new_data.bill_info.district = (new_city === CITY_DEFAULT_VALUE) ? DISTRICT_DEFAULT_VALUE : taiwan_districts.find(city=>city.name === new_city).districts[0].name;
                                            setFormData(new_data);
                                        }}
                                        style={{
                                            marginBottom: '4px'
                                        }}
                                    >
                                        <option value="">Tỉnh</option>
                                        {
                                            taiwan_districts.map((city, i)=><option key={i} value={city.name}>{city.name}</option>)
                                        }
                                    </Form.Select>
                                    <Form.Select 
                                        className='flex-grow-1 ms-1'
                                        name="bill_info_district"
                                        required
                                        value={form_data.bill_info.address_1}
                                        onChange={e=>{
                                            let new_data = Object.assign({}, form_data);
                                            new_data.bill_info.address_1 = e.currentTarget.value;
                                            setFormData(new_data);
                                        }}
                                        style={{
                                            marginBottom: '4px'
                                        }}
                                    >
                                        <option value="">thành phố</option>
                                        {
                                            (form_data.bill_info.city === CITY_DEFAULT_VALUE) ? '' :
                                            ((taiwan_districts.find(city=>city.name === form_data.bill_info.city) || { districts: [] }).districts || []).map((district, i)=><option key={i} value={district.name}>{district.name}</option>)
                                        }
                                    </Form.Select>
                                </div>
                                <Form.Control
                                    id={`bill_info_address_2`}
                                    type='text'
                                    placeholder="Vui lòng nhập địa chỉ đầy đủ của bạn"
                                    name="address_2"
                                    required={true}
                                    onChange={e=>{
                                        let new_data = Object.assign({}, form_data);
                                        new_data.bill_info.address_2 = e.currentTarget.value;
                                        setFormData(new_data);
                                    }}
                                    value={form_data.bill_info.address_2 || ''}
                                />
                            </FormGroup>
                            <Form.Control.Feedback className='text-end' type="invalid">
                                Địa chỉ giao hàng không được để trống
                            </Form.Control.Feedback>
                        </div>
                        <Form.Group className="mt-16">
                                <Form.Check>
                                    <Form.Check.Input
                                        id={`bill_info_save_check`}
                                        type='checkbox'
                                        checked={bill_info_check}
                                        value='bill_info'
                                        onChange={e=>{ 
                                            let checked = bill_info_check;
                                            checkBillInfo(!checked);
                                        }}
                                    />
                                    <Form.Label htmlFor={`bill_info_save_check`} className="user-select-none ms-1">Đặt thông tin mặc định cho thanh toán</Form.Label>
                                </Form.Check>
                        </Form.Group>
                    </div>
                    <div className="block pt-12 pb-20 mb-16">
                        <div className='title underline'>Được thành lập bởi biên tập viên thống nhất</div>
                        <Form.Group>
                            <Form.Label className='user-select-none my-12' htmlFor="company">Tên công ty</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Vui lòng nhập tên công ty đầy đủ'
                                id="company"
                                name='company'
                                value={form_data.bill_info.company || ''}
                                onChange={e=>{
                                    let new_data = Object.assign({}, form_data);
                                    new_data.bill_info.company = e.currentTarget.value;
                                    setFormData(new_data);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className='user-select-none my-12' htmlFor="tax_id">Số thống nhất</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='xxxxxxxx'
                                id="tax_id"
                                name='tax_id'
                                value={form_data.bill_info.tax_id || ''}
                                style={{
                                    marginBottom: '4px'
                                }}
                                onChange={e=>{
                                    let new_data = Object.assign({}, form_data);
                                    new_data.bill_info.tax_id = e.currentTarget.value;
                                    setFormData(new_data);
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className="block pt-12 pb-20 mb-16">
                            <div className='title'>Nhận xét</div>
                            <Form.Control
                                type='text'
                                placeholder='Nhận xét'
                                name='remark'
                                id="remark"
                                value={form_data.remark || ''}
                                onChange={e=>{
                                    let new_data = Object.assign({}, form_data);
                                    new_data.remark = e.currentTarget.value;
                                    setFormData(new_data);
                                }}
                            />
                    </div>
                    <div className='block mb-16'>
                        <div className='title'>Nếu một số sản phẩm tạm thời không có sẵn</div>
                        <Form.Check>
                            <Form.Check.Input
                                id="stock_treatment_filter"
                                type='radio'
                                name="stock_treatment"
                                value="filter"
                                checked={stock_treatment === "filter"}
                                onChange={e=>{ setStockTreatment(e.currentTarget.value); }}
                            />
                            <Form.Label htmlFor="stock_treatment_filter" className="user-select-none ms-1">Mình nhận một số mặt hàng hết hàng và chỉ nhận những mặt hàng còn trong kho</Form.Label>
                        </Form.Check>
                        <Form.Check>
                            <Form.Check.Input
                                id="stock_treatment_full"
                                type='radio'
                                name="stock_treatment"
                                value="full"
                                checked={stock_treatment === "full"}
                                onChange={e=>{ setStockTreatment(e.currentTarget.value); }}
                            />
                            <Form.Label htmlFor="stock_treatment_full" className="user-select-none ms-1">Hủy toàn bộ đơn hàng</Form.Label>
                        </Form.Check>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-16">
                        <Button variant="primary" className='submit_button mono-button dark' name="submit_button" type="submit">Gửi đơn đặt hàng</Button>
                    </div> 
                </div>
            </Form>
        </div>
    </div>;
}


export default Checkout;