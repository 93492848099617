import './style.css';
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Collapse, Form, Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { 
    BASE_ROUTE,
    ORDER_STATUS_LIST, 
    PAYMENT_LIST, 
    SESSION_EXPIRED_CODE,
} from "../../config";
import { http_request } from "../../connection";
import { createTimeString, logout, numberToCash } from "../../lib";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from '../../components/toast';



export function Order(){
    const url_params = useParams();
    const order_id = url_params.id;
    const location = useLocation();
    const [order, setOrder] = useState({});
    const [cancel_order_dialog_show, showCancelOrderDialog] = useState(false);
    const [result_dialog_show, showResultDialog] = useState(false);
    const [payment_dialog_show, showPaymentDialog] = useState(false);
    const [result_text, setResultText] = useState('');

    useEffect(()=>{
        loadOrder();
    }, [location]);

    function loadOrder(){
        var params = {
            order_id
        };
        return new Promise(resolve=>{
            http_request('/order/get', 'POST', params).then(res=>{
                if(res.msg == SESSION_EXPIRED_CODE){
                    logout();
                    return;
                }else{
                    if(res.success){
                        setOrder(res.data.order);
                        resolve();
                    }
                }
            });
        });
    }
    function popResultDialog(msg){
        setResultText(msg);
        showResultDialog(true);
    }
    return <div id="page-order" className="page-content d-flex flex-column flex-grow-1">
        <div className='col-12 col-sm-6 offset-sm-3 d-flex flex-column flex-grow-1 p-16'>
            <ResultDialog 
                dialog_show={result_dialog_show} 
                showDialog={showResultDialog}
                text={result_text}
            />
            <CancelOrderDialog 
                order_id={order.id}
                dialog_show={cancel_order_dialog_show} 
                showDialog={showCancelOrderDialog}
                popResultDialog={popResultDialog}
                loadOrder={loadOrder}
            />
            <PaymentChangeDialog 
                order_id={order.id}
                payment={order.payment} 
                dialog_show={payment_dialog_show} 
                showDialog={showPaymentDialog}
                popResultDialog={popResultDialog}
                loadOrder={loadOrder}
            />
            <OrderInfo order={order} />
            {
                order.payment === "Chuyển mạng" ? <PaymentInfo order={order} showDialog={showPaymentDialog} showResultDialog={showResultDialog} /> : ''
            }
            <ItemList order={order} />
            <Summary order={order} />
            <DeliveryInfo order={order} />
            <BillInfo order={order} />
            {
                (order.status === 'pending_payment') ? <>
                    <div className='d-flex p-1'>
                        <button type='button' className='button mono-button light flex-grow-1' onClick={()=>{ 
                            showPaymentDialog(true);
                        }}>Thay đổi phương thức thanh toán</button>
                    </div>
                    <div className='d-flex p-1'>
                        <button type='button' className='button mono-button light flex-grow-1' onClick={()=>{ 
                            showCancelOrderDialog(true);
                        }}>Hủy đơn hàng</button>
                    </div>
                </> : ''
            }
        </div>
    </div>;
}

function CancelOrderDialog(props){
    const navigate = useNavigate();
    const { dialog_show, showDialog, order_id, loadOrder, popResultDialog } = props;
    function cancelOrder(){
        let params = {
            order_id
        };
        return new Promise(resolve=>{
            http_request('/order/cancel', 'POST', params).then(res=>{
                if(res.msg == SESSION_EXPIRED_CODE){
                    logout();
                    resolve();
                    return;
                }else{
                    if(res.success){
                        navigate(`${BASE_ROUTE}/account/order_list`);
                    }else{
                        popResultDialog(res.msg, true);
                    }
                    resolve();
                }
            });
        });
    }
    return <Modal
            show={dialog_show}
            centered
            onHide={()=>{ showDialog(false); }}
        >
        <div id="page-order-list-date-selector" className='p-16 pb-20'>
            <div className='text-center fs-16 fw-bold' style={{ marginBottom: '36px', marginTop: '24px' }}>
                Bạn có muốn hủy đơn hàng này không?
            </div>
            <div className='d-flex justify-content-center'>
                <button type='button' className='mono-button button light flex-grow-1' style={{ marginRight: '5px' }} onClick={()=>{ showDialog(false); }}>我要保留</button>
                <button type='button' className='mono-button button dark flex-grow-1' style={{ marginLeft: '5px' }} onClick={()=>{
                    cancelOrder();
                    showDialog(false);
                }}>Hủy đơn hàng</button>
            </div>
        </div>
    </Modal>
}
function ResultDialog(props){
    const {dialog_show, showDialog, text} = props;
    return <Modal
            show={dialog_show}
            centered
            onHide={()=>{ showDialog(false); }}
        >
        <div id="page-order-list-date-selector" className='p-16'>
            <div className='text-center fs-16 fw-bold' style={{ marginBottom: '36px', marginTop: '24px' }}>
                {text || 'Thao tác không thành công'}
            </div>
            <div className='d-flex justify-content-center pb-8'>
                <button type='button' className='mono-button button dark flex-grow-1' style={{ maxWidth: '311px' }} onClick={()=>{
                    showDialog(false);
                }}>Chắc chắn</button>
            </div>
        </div>
    </Modal>
}
function PaymentChangeDialog(props){
    const { dialog_show, showDialog, order_id, loadOrder, popResultDialog } = props;
    const [payment, setPayment] = useState(props.payment);
    const [toast_in, showToast] = useState(false);

    useEffect(()=>{
        setPayment(props.payment);
    }, [props.payment]);

    function updatePayment(){
        let params = {
            order_id,
            payment
        };
        return new Promise(resolve=>{
            http_request('/order/editPayment', 'POST', params).then(res=>{
                if(res.msg == SESSION_EXPIRED_CODE){
                    logout();
                    resolve();
                    return;
                }else{
                    if(res.success){
                        loadOrder();
                        showToast(true);
                    }else{
                        popResultDialog(res.msg, true);
                    }
                    resolve();
                }
            });
        });
    }
    return <Fragment>
        <Toast children={<span>
            <img src={`${process.env.PUBLIC_URL}/assets/icon-toast-success.svg`} />
            <span>已Thay đổi phương thức thanh toán</span>
        </span>} toast_in={toast_in} showToast={showToast}/>
        <Modal
            show={dialog_show}
            centered
            onHide={()=>{ showDialog(false); }}
        >
            <div className='p-16 pb-20'>
                <div className='underline pb-12 text-center fs-16 fw-bold'>
                    Thay đổi phương thức thanh toán
                </div>
                {
                    PAYMENT_LIST.map((payment_candidate, p)=><Form.Check key={p} className='underline py-12 d-flex align-items-center justify-content-between'>
                        <Form.Label htmlFor={`candidate_${p}`} className="user-select-none mb-0 flex-grow-1">{payment_candidate}</Form.Label>
                        <Form.Check.Input
                            className='me-8'
                            id={`candidate_${p}`}
                            type='radio'
                            name="payment"
                            value={payment_candidate}
                            checked={payment === payment_candidate}
                            onChange={e=>{ setPayment(e.currentTarget.value); }}
                        />
                    </Form.Check>)
                }
                <div className='d-flex justify-content-center' style={{ marginTop: '24px', marginBottom: '4px' }}>
                    <button type='button' className='mono-button button light flex-grow-1' style={{ marginRight: '5px' }} onClick={()=>{ showDialog(false); }}>Hủy bỏ</button>
                    <button type='button' className='mono-button button dark flex-grow-1' disabled={!payment} style={{ marginLeft: '5px' }} onClick={()=>{
                        updatePayment();
                        showDialog(false);
                    }}>thay đổi</button>
                </div>
            </div>
        </Modal>
    </Fragment>
}
function OrderInfo(props){
    let { order } = props;
    return <div className='block px-16 pb-16 pt-0 mb-16'>
        <div className='underline py-12 mb-12 fw-bold'>Thông tin đặt hàng</div>
        <div>
            <table className="w-100">
                <tbody>
                    <tr>
                        <td>số thứ tự</td>
                        <td className='text-end'>{order.id}</td>
                    </tr>
                    <tr>
                        <td>ngày đặt hàng</td>
                        <td className='text-end'>{createTimeString('y/m/d', order.datetime ? ( new Date(order.datetime) ) : ( new Date() ))}</td>
                    </tr>
                    <tr>
                        <td>tổng số tiền</td>
                        <td className='text-end'>NT{numberToCash(order.total)}</td>
                    </tr>
                    <tr>
                        <td>Phương thức thanh toán</td>
                        <td className='text-end'>{order.payment}</td>
                    </tr>
                    <tr>
                        <td>Trạng thái đơn hàng</td>
                        <td className='text-end'>{(ORDER_STATUS_LIST[order.status] || {}).name}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>;
}
function PaymentInfo(props){
    let { order } = props;
    const [toast_in, showToast] = useState(false);
    return <React.Fragment>
            <Toast children={<span>
                <img src={`${process.env.PUBLIC_URL}/assets/icon-toast-success.svg`} />
                <span>Đã sao chép</span>
            </span>} toast_in={toast_in} showToast={showToast}/>
            <div className='block pt-12 mb-16 payment-info'>
                <div className='underline fw-bold pb-12'>付款資訊</div>
                <div className='underline fw-bold py-12 bank-info'>
                    <img src={`${process.env.PUBLIC_URL}/assets/chinatrust-logo.svg`} />
                    <span>VCB</span>
                </div>
                <div className='underline py-12'>
                <div className='label'>Tên tài khoản</div>
                <div className='strong'>CTY TNHH TANG TRUONG TC VN</div>
            </div>
            <div className='underline py-12 account'>
                <div className='label'>Số tài khoản</div>
                <div className='strong d-flex align-items-center justify-content-between'>
                    <span>
                        <span className='account-code'>1049926666</span>
                    </span>
                        <CopyToClipboard className='copy-button' text='4383800066429522' onCopy={()=>{ showToast(true); }}>
                            <img src={`${process.env.PUBLIC_URL}/assets/icon-button-copy-normal.svg`} />
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='underline py-12'>
                    <div className='label'>Số tiền thanh toán</div>
                    <div className='strong'>
                        NT{numberToCash((order.order_details || []).reduce(function(p, c){
                            return p + (c.discount_price * c.quantity);
                        }, 0) + (order.delivery_fee || 0))}
                    </div>
                </div>
                <div className='underline py-12 expiration'>
                    <div className='label'>thời hạn thanh toán</div>
                    <div className='strong'>Hết hạn lúc 23:14 ngày 8 tháng 8 năm 2024</div>
                    <div className='warning'>Còn lại 2 ngày, 23 giờ và 59 phút</div>
                    <div className='warning'>*Nếu quá thời hạn thanh toán không được thực hiện, đơn hàng sẽ tự động bị hủy.</div>
                </div>
                <div className='py-12'>
                    <div className='label'>Những điều cần lưu ý</div>
                    <ol className='ps-12 mb-0 tips'>
                        <li>Vui lòng sử dụng ngân hàng trực tuyến hoặc ATM vật lý để chuyển số tiền vào tài khoản trên.</li>
                        <li>Bạn có thể kiểm tra trạng thái thanh toán trên trang đặt hàng 30 phút sau khi quá trình chuyển khoản hoàn tất.</li>
                        <li>Vui lòng giữ lại biên lai hóa đơn cho đến khi khoản thanh toán được ghi có. Nếu khoản thanh toán không được ghi có sau 48 giờ kể từ khi chuyển khoản, vui lòng liên hệ lại với chúng tôi để được giải đáp.</li>
                    </ol>
                </div>
            </div>
        </React.Fragment>;
}
function Summary(props){
    let { order } = props;
    return <div className='block pt-12 mb-16'>
        <div className='underline fw-bold pb-12'>Thông tin số tiền</div>
        <div className='py-12 underline'>
            <div className='d-flex align-items-center justify-content-between'>
                <span>Tổng phụ：</span>
                <span>NT{numberToCash((order.order_details || []).reduce(function(p, c){
                    return p + (c.original_price * c.quantity);
                }, 0))}</span>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
                <span>Giảm giá：</span>
                <span>-NT{numberToCash((order.order_details || []).reduce(function(p, c){
                    return p + (c.original_price * c.quantity);
                }, 0) - (order.total || 0))}</span>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
                <span>vận chuyển hàng hóa：</span>
                <span>NT{numberToCash(order.delivery_fee)}</span>
            </div>
        </div>
        <div className='d-flex align-items-center justify-content-between pt-12 fw-bold'>
            <span>tổng cộng：</span>
            <span>NT{numberToCash((order.order_details || []).reduce(function(p, c){
                    return p + (c.discount_price * c.quantity);
                }, 0) + (order.delivery_fee || 0))}</span>
        </div>
    </div>;
}
function DeliveryInfo(props){
    let { order } = props;
    return <div className='block pt-12 mb-16'>
        <div className='underline fw-bold pb-12'>Thông tin vận chuyển hàng hóa</div>
        <div className='mt-12'>
            <table className="w-100">
                <tbody>
                    <tr>
                        <td>Tên người nhận：</td>
                        <td className='text-end'>{(order.order_shipping_info || {}).name}</td>
                    </tr>
                    <tr>
                        <td>Số điện thoại người nhận：</td>
                        <td className='text-end'>{(order.order_shipping_info || {}).contact_number}</td>
                    </tr>
                    <tr>
                        <td>Địa điểm giao hàng：</td>
                        <td className='text-end'>
                            {(order.order_shipping_info || {}).postal_code}
                            {(order.order_shipping_info || {}).address_1}
                            {(order.order_shipping_info || {}).address_2}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>;
}
function BillInfo(props){
    let { order } = props;
    return <div className='block pt-12 mb-16'>
        <div className='underline fw-bold pb-12'>Thông tin thanh toán</div>
        <div className='mt-12'>
            <table className="w-100">
                <tbody>
                    <tr>
                        <td>Tên người nhận：</td>
                        <td className='text-end'>{(order.order_bill_info || {}).name}</td>
                    </tr>
                    <tr>
                        <td>Số điện thoại người nhận：</td>
                        <td className='text-end'>{(order.order_bill_info || {}).contact_number}</td>
                    </tr>
                    <tr>
                        <td>Địa điểm giao hàng：</td>
                        <td className='text-end'>
                            {(order.order_bill_info || {}).postal_code}
                            {(order.order_bill_info || {}).address_1}
                            {(order.order_bill_info || {}).address_2}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
}
function ItemList(props){
    let { order } = props;
    const [product_list_show, showProductList] = useState(true);
    return <div className='product-info block px-16 py-0 mb-16'>
        <div className='title py-12 d-flex align-items-center justify-content-between'>
            <span className='fw-bold'>Thông tin sản phẩm</span>
            <span className={`ps-20 ${product_list_show ? '' : 'closed'}`} onClick={()=>{
                showProductList(!product_list_show);
            }}>
                <img src={`${process.env.PUBLIC_URL}/assets/icon-button-arrow-up-normal.svg`} />
            </span>
        </div>
        <Collapse in={product_list_show}>
            <div>
                <div className='underline' style={{ marginBottom: '4px' }}></div>
                <div className='item-list'>
                    {
                        (order.order_details || []).map((item, i)=><div key={i} className="d-flex item py-8">
                            <div className='img-container'>
                                {
                                    item.product.images.length ? <img className='product-picture' src={item.product.images[0]} /> : ''
                                }
                            </div>
                            <div className='ps-12 d-flex flex-column flex-grow-1'>
                                <div className='name fw-bold'>{item.product.name}</div>
                                <div className='d-flex flex-column justify-content-end flex-grow-1'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span></span>
                                        {
                                            item.original_price != item.product.discount_price ? <span className='fs-12 text-muted text-decoration-line-through'>{numberToCash(item.original_price)}</span> : ''
                                        }
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='fs-12'>
                                            <span>Số lượng：</span>
                                            <span>{item.quantity}</span>
                                        </span>
                                        <span className='fw-bold'>{numberToCash(item.product.discount_price)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                    {/* <div className='d-flex mt-16 mb-20'>
                        <button type='button' className='flex-grow-1 mono-button button dark'>再次thêm vào giỏ hàng</button>
                    </div> */}
                </div>
            </div>
        </Collapse>
    </div>;
}

export default Order;