import '../../assets/icon-button-amount-normal.svg';
import './style.css';
import React, { useEffect, useState } from "react";
import { Button, Carousel, Form, Modal } from "react-bootstrap";
import { NumberButton } from '../../components/number_button';
import { Link, useLocation, useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { BASE_ROUTE, PAGE_SIZE, SESSION_EXPIRED_CODE, EXTEND, REPLACE } from "../../config";
import { http_request } from "../../connection";
import { logout, calculateDiscount, bySort } from "../../lib";
import { autoFill } from "./tools";
import LoadingTip from "../../components/loading_tip";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import Toast from "../../components/toast";
import { Fragment } from 'react';

/**
 * 
 * v 前綴的全域變數是經常變來變去, 不要等 useStatus callback 的變數
 * 有些 v 前綴的變數會跟一個 status 變數成對, 因為他們也有顯示需求
 * 我不知道有啥更好作法反正先這樣
 * 
 * */

var current_page = 1;
var v_data_size = null;
var waiting_for_cart_refresh = false;
var v_search = '';
var options = {};

const NORMAL = 0;
const FILTERED = 1;
const FILTERED_WHIT_OPTIONS = 2;

const TYPE_SEARCH = 0;
const TYPE_FILTER = 1;

function Gallery(props){
    const location = useLocation();
    const navigate = useNavigate();
    const url_params = useParams();
    const [search_params, setSearchParams] = useSearchParams();
    const { loadCart, shopping_cart, product_categories } = useOutletContext();
    const [products, setProducts] = useState([]);
    const [cart_tip, setCartTip] = useState('');
    const [toast_in, showToast] = useState(false);
    const [is_loading, setLoadingProgress] = useState(false);
    const [cate_path, setCatePath] = useState([]);
    const [next_cate_level, setNextCateLevel] = useState({});
    const [product_info_show, showProductInfo] = useState(false);
    const [filter_show, showFilter] = useState(false);
    const [selected_product, selectProduct] = useState(calculateDiscount({}));
    const [count, setCount] = useState(1);
    var category_id = url_params.category || null;
    if(category_id === '#') category_id = null;

    const [data_size, setDataSize] = useState(v_data_size);

    v_search = search_params.get('search') || '';
    const [search, setSearch] = useState(v_search);
    const [filter_status, setFilterStatus] = useState(v_search.length ? FILTERED : NORMAL);


    const [filter_sort_by_create, setFilterSortByCreate] = useState('desc');
    const [filter_sort_by_price, setFilterSortByPrice] = useState('asc');
    const [customize_price_range, setCustomizePriceRange] = useState(false);
    const [price_range_start, setPriceRangeStart] = useState('');
    const [price_range_end, setPriceRangeEnd] = useState('');

    useEffect(() => {
        resetOptions();
        v_search = search_params.get('search') || '';
        current_page = 1;
        loadProducts(REPLACE);
        findCategoryPath();

        //完全用來 display 的 status 變數
        setSearch(v_search);
        setFilterStatus(v_search.length ? FILTERED : NORMAL);
    }, [location, search_params]);


    useEffect(()=>{
        findCategoryPath();
    }, [product_categories]);

    useEffect(()=>{
        if(waiting_for_cart_refresh) setLoadingProgress(false);
    }, [shopping_cart]);


    function loadProducts(method = EXTEND){
        if(is_loading) return;
        var params = {
            page: current_page,
            rows: PAGE_SIZE
        };
        if(category_id !== null) params.product_category_id = category_id;
        if(v_search.length){
            params.search = v_search;
            if(typeof options === 'object' && Object.keys(options).length){
                for(let p in options){
                    params[p] = options[p];
                }
                setFilterStatus(FILTERED_WHIT_OPTIONS);
            }else{
                setFilterStatus(FILTERED);
            }
        }else{
            setFilterStatus(NORMAL);
        }
        setLoadingProgress(true);
        http_request('/product/list', 'POST', params).then(res=>{
            setLoadingProgress(false);
            if(res.msg == SESSION_EXPIRED_CODE){
                logout();
                return;
            }else{
                let temp_products = (res.data.products || []).map(autoFill).map(calculateDiscount);
                switch(method){
                    case EXTEND:
                        setProducts(products.concat(temp_products));
                        break;
                    case REPLACE:
                        setProducts(temp_products);
                        break;
                }
                v_data_size = res.data.total;
                setDataSize(v_data_size);
            }
        });
    }
    function loadProductInfo(product_id){
        if(is_loading) return;
        let params = { product_id };
        setLoadingProgress(true);
        http_request(`/product/get`, 'POST', params).then(res=>{
            setLoadingProgress(false);
            if(res.msg == SESSION_EXPIRED_CODE){
                logout();
                return;
            }else{
                let pi = calculateDiscount(res.data.product ? res.data.product : {});
                selectProduct(autoFill(pi));
            }
        });
    }
    function loadMore(e){
        let { scrollHeight, clientHeight, scrollTop } = e.target;
        if(scrollHeight - clientHeight - scrollTop <= 2){
            if(is_loading) return;
            if(v_data_size > 0 && (current_page * PAGE_SIZE) > v_data_size) return;
            current_page += 1;
            loadProducts();
        }
    }
    function findCategoryPath(){
        let path = [];
        if(!category_id){
            setCatePath([product_categories]);
            setNextCateLevel(product_categories.children || {});
            return;
        }
        function find(node){
            path.push(node);
            if(node.id == category_id){
                return true;
            }else{
                if(Object.keys(node.children).length){
                    for(let p in node.children){
                        let res = find(node.children[p]);
                        if(res) return true;
                    }
                }
                path = path.slice(0, path.length - 1);
                return false;
            }
        }
        find(product_categories);
        setCatePath(path);
        if(path.length){
            setNextCateLevel(path[path.length - 1].children || {})
        }else{
            setNextCateLevel({});
        }
    }
    function showProduct(product_id){
        loadProductInfo(product_id);
        showProductInfo(true);
        setCount(1);
    }
    function modifyCount(c){
        if(count + c < 1){
            setCount(1);
        }else{
            setCount(count + c);
        }
    }
    function addToCart(){
        if(is_loading) return;
        const i = shopping_cart.findIndex(p=>{
            return p.product.id == selected_product.id;
        });
        const is_new = (i == -1);
        var quantity, method, params;
        if(is_new){
            quantity = count;
            method = 'create';
            params = {
                product_id: selected_product.id,
                quantity
            };
        }else{
            quantity = shopping_cart[i].quantity += count;
            method = 'edit';
            params = {
                product_id: selected_product.id,
                quantity,
                shopping_cart_id: shopping_cart[i].id
            };
        }
        setLoadingProgress(true);
        return new Promise((resolve, reject)=>{
            http_request(`/shopping-cart/${method}`, 'POST', params).then(res=>{
                setLoadingProgress(false);
                if(res.msg == SESSION_EXPIRED_CODE){
                    resolve();
                    logout();
                    return;
                }else{
                    if(res.success){
                        setCartTip(<React.Fragment>
                            <img src={`${process.env.PUBLIC_URL}/assets/icon-toast-success.svg`} />
                            <span style={{ paddingLeft: '6px' }}>Đã thêm vào giỏ hàng</span>
                            </React.Fragment>
                        );
                        showToast(true);
                        loadCart();
                        showProductInfo(false);
                        resolve();
                    }else{
                        setCartTip(Object.values(res.data));
                        showProductInfo(false);
                        showToast(true);
                        resolve();
                    }
                }
            });
        });
    }
    function purchaseNow(){
        addToCart().then(()=>{
            navigate(`${BASE_ROUTE}/cart`);
        }).catch(()=>{
            console.log('purchase failed');
        });
    };
    function resetOptions(){
        options = {};
        setFilterSortByCreate('desc');
        setFilterSortByPrice('asc');
        setPriceRangeStart('');
        setPriceRangeEnd('');
        setCustomizePriceRange(false);
    }
    function popFilterOptionPannel(){
        resetOptions();
        showFilter(true);
    }
    function filterProducts(){
        let q1 = !(filter_sort_by_create || filter_sort_by_price || customize_price_range);
        let q2 = customize_price_range && ( isNaN(price_range_start) || isNaN(price_range_end) );
        let q3 = customize_price_range && ( (price_range_start === '') || (price_range_end === '') );
        let q4 = customize_price_range && ( parseInt(price_range_start) > parseInt(price_range_end) );

        if(q1 || q2 || q3 || q4){
            //此處應插一個錯誤提示 toast
            console.log({ q1, q2, q3, q4 });
            return;
        }

        options = {};
        options.sortByCrate = filter_sort_by_create;
        if(filter_sort_by_price.substring(0,9) === 'discount-'){
            options.sortByDiscountPrice = filter_sort_by_price.replace('discount-', '');
        }else{
            options.sortByPrice = filter_sort_by_price;
        }
        if(customize_price_range){
            options.minPrice = price_range_start;
            options.maxPrice = price_range_end;
        }
        current_page = 1;
        loadProducts(REPLACE);
        showFilter(false);
    }
    return (<div id="page-gallery" className="page-content" onScroll={e=>{ loadMore(e); }}>
        <LoadingTip is_loading={is_loading} />
        <Toast 
            toast_in={toast_in}
            showToast={showToast}
            children={cart_tip}
        />
        <Modal 
            show={product_info_show}
            onHide={()=>{ showProductInfo(false); }}
            centered    
        >
            <div className="page-gallery-product-info-pop">
                <div className="d-flex body underline">
                        <div className="bg-light d-flex align-items-center justify-content-center" style={{ 
                            width: '128px', 
                            height: '128px',
                            borderRadius: '4px',
                        }}>
                        {
                            (selected_product.images && selected_product.images.length) ? <img className='product-picture' src={selected_product.images[0]} /> : ''
                        }
                    </div>
                    <div className="info d-flex flex-column overflow-hidden" style={{ flex: 1, marginLeft: '16px' }}>
                        <div className="name">{selected_product.name}</div>
                        <div className="brand">SHEIN</div>
                        <div className={`discount-info ${selected_product.discount_rate === null ? 'd-none' : ''}`}>
                            <span className="rate">{selected_product.discount_rate}Giảm giá</span>
                            <span className="date">Đề nghị cho {selected_product.discount_expiration.y}/{selected_product.discount_expiration.m}/{selected_product.discount_expiration.d}</span>
                        </div>
                        <div>
                            {
                                selected_product.price == selected_product.discount_price ? <span className="price discount_price">${selected_product.price}</span> : <React.Fragment>
                                    <span className="price discount_price">${selected_product.discount_price}</span>
                                    <span className="price text-muted text-decoration-line-through" style={{ marginLeft: '4px' }}>${selected_product.price}</span>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
                <div className="foot">
                    <div className={`d-flex align-items-center ${selected_product.stock == 0 ? 'pb-8' : ''}`}>
                        <span className="flex-grow-1 label">Số lượng</span>
                        <span className="d-flex align-items-center">
                            <NumberButton className="number-button" icon={DashCircle} disabled={count == 1 || selected_product.stock == 0} onClick={()=>{ modifyCount(-1) }} />
                            {
                                selected_product.stock == 0 ? <span style={{
                                    color: '#fd5c53',
                                    whiteSpace: 'nowrap'
                                }}>Hết hàng</span> : <span className="count">{count}</span>
                            }
                            <NumberButton className="number-button" icon={PlusCircle} disabled={selected_product.stock == 0} onClick={()=>{ modifyCount(1) }} />
                        </span>
                    </div>
                    {
                        selected_product.stock > 0 ? <div className="button-group">
                            <button className="check-button light flex-grow-1" type="button" onClick={()=>{ addToCart(); }} style={{ marginRight: '5px' }}>thêm vào giỏ hàng</button>
                            <button className="check-button dark flex-grow-1" type="button" onClick={()=>{ purchaseNow(); }} style={{ marginLeft: '6px' }}>Mua nó ngay bây giờ</button>
                        </div> : ''
                    }
                </div>
            </div>
        </Modal>
        <Modal
            id="search-filter-pannel"
            show={filter_show}
            centered
            onHide={()=>{ showFilter(false); }}
            className="p-16"
        >
            <div className="">
                <div className="underline mx-16 pt-16 pb-12 text-center fw-bold fs-16">lọc</div>
                <div className="underline mx-16 pb-16" style={{ paddingTop: '4px' }}>
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center selector-label">
                            <img src={`${process.env.PUBLIC_URL}/assets/icon-display-time.svg`} />
                            <span className="flex-grow-1">Thời gian bảo quản</span>
                        </div>
                        <select value={filter_sort_by_create} onChange={e=>{
                            setFilterSortByCreate(e.currentTarget.value);
                        }}>
                            <option value="desc">Từ mới đến cũ</option>
                            <option value="asc">Từ cũ đến mới</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center selector-label">
                            <img src={`${process.env.PUBLIC_URL}/assets/icon-display-amount.svg`} />
                            <span className="flex-grow-1">số lượng sản phẩm</span>
                        </div>
                        <select value={filter_sort_by_price} onChange={e=>{
                            setFilterSortByPrice(e.currentTarget.value);
                        }}>
                            <option value="asc">Tất cả - từ thấp đến cao</option>
                            <option value="desc">Tất cả - từ cao đến thấp</option>
                            <option value="discount-asc">Giảm giá - từ thấp đến cao</option>
                            <option value="discount-desc">Giảm giá - từ cao đến thấp</option>
                        </select>
                    </div>
                </div>
                <div className="mx-16 pt-16 pb-16 mb-12">
                    <Form.Group>
                        <Form.Check>
                            <Form.Check.Input
                                id={`customize_price_range`}
                                type='checkbox'
                                checked={customize_price_range}
                                value='customize_price_range'
                                onChange={e=>{ 
                                    let checked = customize_price_range;
                                    if(checked){
                                        setPriceRangeStart('');
                                        setPriceRangeEnd('');
                                    }
                                    setCustomizePriceRange(!checked);
                                }}
                            />
                            <Form.Label htmlFor={`customize_price_range`} className="user-select-none ms-1">Số tiền tùy chỉnh</Form.Label>
                        </Form.Check>
                    </Form.Group>
                    <div className="d-flex price_range_container align-items-center">
                        <input 
                            disabled={!customize_price_range}
                            type="number" 
                            className="flex-grow-1" 
                            placeholder="0"
                            value={price_range_start}
                            onChange={e=>{ 
                                if(isNaN(e.target.value)) return;
                                setPriceRangeStart(e.target.value);
                            }}
                        />
                        <span
                            style={{
                                paddingLeft: '7px',
                                paddingRight: '7px'
                            }}
                        >-</span>
                        <input 
                            disabled={!customize_price_range}
                            type="number" 
                            className="flex-grow-1" 
                            placeholder="1000"
                            value={price_range_end}
                            onChange={e=>{ 
                                if(isNaN(e.target.value)) return;
                                setPriceRangeEnd(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="mt-12 d-flex mb-20 mx-16">
                    <button type="button" className="flex-grow-1 button mono-button light" style={{ marginRight: '5px' }} onClick={()=>{ showFilter(false); }}>Hủy bỏ</button>
                    <button type="button" className="flex-grow-1 button mono-button dark" style={{ marginLeft: '5px' }} onClick={()=>{ filterProducts(); }}>Áp dụng</button>
                </div>
            </div>
        </Modal>
        {
            filter_status === NORMAL ? <Fragment>
                <ADPannel />
                <BreadcrumbPannel cate_path={cate_path.filter(bySort)} />
            </Fragment> : <FilterTipPannel data_size={data_size} search={search} filter_status={filter_status} popPannel={popFilterOptionPannel} />
        }
        {
            <ContentBlock
                params={{
                    data_size,
                    filter_status,
                    products,
                    showProduct
                }}
            />
        }
    </div>);
}
function ContentBlock(props){
    /**
     * 有關鍵字 + 有 filter 但沒商品 -> EmptySearch type={TYPE_FILTER}
     * 有關鍵字但沒商品 -> EmptySearch type={TYPE_SEARCH}
     * 有關鍵字且有商品 -> ProductListPannel
     * 沒關鍵字 -> ProductListPannel
     */
    let { data_size, filter_status, products, showProduct } = props.params;
    if(data_size > 0){
        return <ProductListPannel products={products} showProduct={showProduct} />;
    }
    if(filter_status === NORMAL){
        return <ProductListPannel products={products} showProduct={showProduct} />;
    }
    if(filter_status === FILTERED_WHIT_OPTIONS && data_size === 0){//關鍵字查不到商品
        return <EmptySearch type={TYPE_FILTER}/>;
    }
    if(filter_status === FILTERED && data_size === 0){
        return <EmptySearch type={TYPE_SEARCH}/>;
    }
}
function FilterTipPannel(props){
    let { data_size, search, filter_status, popPannel } = props;
    return <div id="filter-tip-pannel" className="mt-16 mx-16">
        <div className="d-flex block fs-14 align-items-center">
            <div className="flex-grow-1">
            <span>Có tổng cộng {data_size} mục</span>
            <strong>“{search}”</strong>
            <span>sản phẩm liên quan</span>
            </div>
            <span className="ps-20" onClick={()=>{ if(!data_size && (filter_status !== FILTERED_WHIT_OPTIONS)) return; popPannel(); }}>
                <img src={`${process.env.PUBLIC_URL}/assets/${(!data_size && (filter_status !== FILTERED_WHIT_OPTIONS)) ? 'icon-button-filter-disable.svg' : 'icon-button-filter-normal.svg'}`} />
            </span>
        </div>
        <div className="d-flex justify-content-center">
            <img src={`${process.env.PUBLIC_URL}/assets/triangle.svg`} />
        </div>
    </div>;
}
function ADPannel(){
    return <div className="banner-container">
        <Carousel
            controls={false}
        >
            {
                [1,2,3,4,5,6].map(n=>
                    <Carousel.Item key={n}>
                        <img src={`${process.env.PUBLIC_URL}/assets/banner_0${n}.png`} className="w-100" />
                    </Carousel.Item>
                )
            }
        </Carousel>
        
    </div>
}
function BreadcrumbPannel(props){
    let { cate_path } = props;
    let parent_cate = cate_path.length > 1 ? cate_path[cate_path.length - 2] : { name: 'Tất cả sản phẩm', id: '' };
    let current_cate = cate_path.length > 1 ? cate_path[cate_path.length - 1] : null;
    return <div className='news-container d-flex align-items-center mb-16'>
        {
            <span className='main'>{ cate_path.length > 1 ? parent_cate.name : 'Tất cả sản phẩm'}</span>
        }
        {
            current_cate ? <Fragment>
                <span className='splitter' />
                <span className='slave'>{current_cate.name}</span>
            </Fragment> : ''
        }
    </div>;
}
function ProductListPannel(props){
    const navigate = useNavigate();
    let { products, showProduct } = props;
    return <ul className="product-list">
        {
            products.map((product, i)=>(<li key={i} className="product-item">
                <div className="card-body d-flex" onClick={()=>{
                    navigate(`${BASE_ROUTE}/product/${product.id}`);
                }}>
                    <div className="bg-light d-flex align-items-center justify-content-center" style={{ 
                        width: '128px', 
                        height: '128px',
                        borderRadius: '4px'
                    }}>
                        {
                            product.images.length ? <img className='product-picture' src={product.images[0]} /> : ''
                        }
                    </div>
                    <div className="info d-flex flex-column overflow-hidden" style={{ flex: 1 }}>
                        <div className="name">
                            {product.name}
                        </div>
                        <div className="brand">SHEIN</div>
                        <div className={`discount-info my-8 ${product.discount_rate === null ? 'd-none' : ''}`}>
                            <span className="rate">{product.discount_rate}Giảm giá</span>
                            <span className="date">Đề nghị cho {product.discount_expiration.y}/{product.discount_expiration.m}/{product.discount_expiration.d}</span>
                        </div>
                        <div>
                            {
                                product.price == product.discount_price ? <span className="price discount_price">${product.price}</span> : <React.Fragment>
                                    <span className="price discount_price">${product.discount_price}</span>
                                    <span className="price text-muted text-decoration-line-through" style={{ marginLeft: '4px' }}>${product.price}</span>
                                </React.Fragment>
                            }
                        </div>
                        <div className="d-flex flex-md-row flex-column flex-grow-1 align-items-normal align-items-sm-end justify-content-end justify-content-sm-start">
                            <Button className="btn-sm list-button"  onClick={(e)=>{ 
                                e.stopPropagation();
                                showProduct(product.id);
                            }}>thêm vào giỏ hàng</Button>
                        </div>
                    </div>
                </div>
            </li>))
        }
    </ul>;
}
function EmptySearch(props){
    let { type } = props;
    return <div className="d-flex flex-grow-1 flex-column align-items-center">
        <div className="d-flex flex-column align-items-center justify-content-center" style={{paddingTop: '40px'}}>
            <img src={`${process.env.PUBLIC_URL}/assets/${type === TYPE_SEARCH ? 'image-empty-search.svg' : 'image-empty-filter.svg'}`} />
            <div className="fw-bold fs-14">
                {type === TYPE_SEARCH ? 'Chưa có sản phẩm nào như vậy' : 'Không có sản phẩm phù hợp với tiêu chí tìm kiếm'}
            </div>
        </div>
    </div>;
}
export default Gallery;