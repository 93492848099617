import './style.css';
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { BASE_ROUTE, TAB_ADDRESS_SETTINGS, TAB_MY_ORDERS } from "../../config";
import { useState } from 'react';
import { useEffect } from 'react';


export default function Account(){
    const location = useLocation();
    const [active_tab_key, setActiveTabKey] = useState(TAB_ADDRESS_SETTINGS);

    useEffect(()=>{
        switch(location.pathname){
            case `${BASE_ROUTE}/account/bill_settings`:
                setActiveTabKey(TAB_ADDRESS_SETTINGS);
                break;
            case `${BASE_ROUTE}/account/order_list`:
                setActiveTabKey(TAB_MY_ORDERS);
                break;
            default:
                setActiveTabKey(TAB_ADDRESS_SETTINGS);
                break;
        }
    }, [location]);


    return <div id="page-account" className="page-content d-flex flex-column flex-grow-1 ">
        <div className='head'>
            <Link className={`tab ${active_tab_key === TAB_ADDRESS_SETTINGS ? 'active' : ''}`} to={`${BASE_ROUTE}/account/bill_settings`}>
                <span>thông tin cá nhân</span>
            </Link>
            <Link className={`tab ${active_tab_key === TAB_MY_ORDERS ? 'active' : ''}`} to={`${BASE_ROUTE}/account/order_list`}>
                <span>Yêu cầu đặt hàng</span>
            </Link>
        </div>
        <div className='border-start border-end border-bottom flex-grow-1 d-flex flex-column' style={{ borderColor: '#dee2e6 #dee2e6 #fff', minHeight: 0 }}>
            <Outlet />
        </div>
    </div>;
}
